export const listAdvertisements = /* GraphQL */ `
  query ListAdvertisements(
    $filter: ModelAdvertisementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdvertisements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        link
        imageLocation
        order
        lang
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        order
        translations {
          language
          name
        }
        Subcategories {
          items {
            id
            name
            order
            translations {
              language
              name
            }
            categoryID
            Subsubcategories {
              items {
                id
                name
                order
                translations {
                  language
                  name
                }
                subcategoryID
                hidden
                createdAt
                updatedAt
              }
              nextToken
            }
            hidden
            createdAt
            updatedAt
          }
          nextToken
        }
        hidden
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        brand {
          id
          name
          status
          createdAt
          updatedAt
        }
        brandId
        brandName
        fullText
        images {
          items {
            url
          }
        }
        Reviews {
          items {
            id
            text
            rating
            productId
            userId
            anonymous
            Likes {
              items {
                id
                reviewId
                userId
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        categoryId
        subcategoryID
        subsubcategoryID
        imageFileName
        status
        interactionsScore
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        rating
        productId
        userId
        Likes {
          items {
            id
            reviewId
            userId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        email
        name
        pictureUrl
        gender
        Reviews {
          items {
            id
            text
            rating
            productId
            userId
            Likes {
              items {
                id
                reviewId
                userId
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        dateOfBirth
        Feedback {
          items {
            id
            text
            userId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      brandName
      brand {
        id
        name
        status
        createdAt
        updatedAt
      }
      fullText
      images {
        items {
          id
          url
        }
      }
      Reviews {
        items {
          id
          text
          rating
          productId
          userId
          Likes {
            items {
              id
              reviewId
              userId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      categoryId
      subcategoryID
      subsubcategoryID
      imageFileName
      status
      interactionsScore
      createdAt
      updatedAt
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      text
      rating
      productId
      userId
      Likes {
        items {
          id
          reviewId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      email
      name
      pictureUrl
      gender
      Reviews {
        items {
          id
          text
          rating
          productId
          userId
          Likes {
            items {
              id
              reviewId
              userId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      dateOfBirth
      Feedback {
        items {
          id
          text
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBrands = /* GraphQL */ `
  query ListBrands(
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBrand = /* GraphQL */ `
  query GetBrand($id: ID!) {
    getBrand(id: $id) {
      id
      name
      status
      searchKeywords
      createdAt
      updatedAt
    }
  }
`;
export const searchProducts = /* GraphQL */ `
  query SearchProducts(
    $filter: SearchableProductFilterInput
    $sort: [SearchableProductSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableProductAggregationInput]
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        brand {
          id
          name
          status
          createdAt
          updatedAt
        }
        brandId
        brandName
        fullText
        images {
          items {
            id
            url
          }
        }
        Reviews {
          items {
            id
            text
            rating
            productId
            userId
            Likes {
              items {
                id
                reviewId
                userId
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        categoryId
        subcategoryID
        subsubcategoryID
        imageFileName
        status
        interactionsScore
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks(
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) {
      id
      text
      userId
      createdAt
      updatedAt
    }
  }
`;
export const searchBrands = /* GraphQL */ `
  query SearchBrands(
    $filter: SearchableBrandFilterInput
    $sort: [SearchableBrandSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableBrandAggregationInput]
  ) {
    searchBrands(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        status
        searchKeywords
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const listCollections = /* GraphQL */ `
  query ListCollections(
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCollections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        order
        translations {
          language
          name
          __typename
        }
        subsubcategoryID
        hidden
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCollection = /* GraphQL */ `
  query GetCollection($id: ID!) {
    getCollection(id: $id) {
      id
      title
      order
      translations {
        language
        name
        __typename
      }
      products {
        items {
          id
          productId
          collectionId
          product {
            id
            name
            brand {
              id
              name
              status
              createdAt
              updatedAt
              __typename
            }
            brandId
            brandName
            fullText
            images {
              items {
                id
                url
              }
            }
            Reviews {
              items {
                id
                text
                rating
                productId
                userId
                Likes {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            categoryId
            subcategoryID
            subsubcategoryID
            imageFileName
            status
            interactionsScore
            userId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subsubcategoryID
      hidden
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCollectionProducts = /* GraphQL */ `
  query ListCollectionProducts(
    $filter: ModelCollectionProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCollectionProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        collectionId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
