import React, { useState } from "react";
import { generateClient } from "aws-amplify/api";
import { createBrand } from "../graphql-use/mutations";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";

const NewBrandPage = () => {
  const [name, setName] = useState("");
  const [status, setStatus] = useState("APPROVED");
  const [searchKeywords, setSearchKeywords] = useState("");

  const client = generateClient();

  const handleSave = async () => {
    try {
      // Send API call to create brand
      const result = await client.graphql({
        query: createBrand,
        variables: {
          input: {
            name,
            status,
            searchKeywords
          }
        }
      });

      console.log("New brand created:", result.data.createBrand);

      // Reset form fields after successful creation
      setName("");
      setStatus("APPROVED");
      setSearchKeywords("");

      NotificationManager.success("Brand created successfully", "Success");
    } catch (error) {
      console.error("Error creating brand", error);
      NotificationManager.error("Error creating brand", "Error");
    }
  };

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "auto" }}>
      <h2 style={{ marginBottom: "20px", borderBottom: "1px solid #ddd" }}>
        New Brand
      </h2>
      <p>
        <strong>Name:</strong>{" "}
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{
            width: "100%",
            padding: "8px",
            fontSize: "1em",
            marginBottom: "20px"
          }}
        />
      </p>
      <p>
        <strong>Status:</strong>{" "}
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          style={{
            padding: "8px",
            fontSize: "1em",
            width: "100%",
            marginBottom: "20px"
          }}
        >
          <option value="APPROVED">APPROVED</option>
          <option value="PENDING">PENDING</option>
          <option value="REJECTED">REJECTED</option>
          <option value="DISABLED">DISABLED</option>
        </select>
      </p>
      <p>
        <strong>Search Keywords:</strong>{" "}
        <input
          type="text"
          value={searchKeywords}
          onChange={(e) => setSearchKeywords(e.target.value)}
          placeholder="Enter keywords separated by space"
          style={{
            width: "100%",
            padding: "8px",
            fontSize: "1em",
            marginBottom: "20px"
          }}
        />
      </p>
      <button
        onClick={handleSave}
        style={{
          padding: "10px",
          fontSize: "1em",
          backgroundColor: "#ff7d69",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer"
        }}
      >
        Create Brand
      </button>

      <NotificationContainer />
    </div>
  );
};

export default NewBrandPage;
